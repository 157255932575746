import * as React from "react"
import ReactHtmlParser from "react-html-parser"
// import { Link } from "gatsby"

const IndexPage = ({img, text, imgclass}) => (

	img &&
  <div className={imgclass} style={{ backgroundImage: `url("${img}")` }}>
    
	<div>{ReactHtmlParser(text)}</div>

	<div className="buttonHolder">


	</div>


  </div>
	
)

export default IndexPage
