import gql from "graphql-tag"

const GET_NEXT_PREVIOUS_ITEMS_QUERY = gql`
  query MyQuery($id: ID!) {
  post(id: $id, idType: SLUG) {
    id
    previousPost {
      node {
        id
        title
        slug
      }
    }
    nextPost {
      node {
        id
        title
        slug
      }
    }
  }
}
`

export default GET_NEXT_PREVIOUS_ITEMS_QUERY

