import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SeoBasic from "../../components/seo"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import ReactHtmlParser from "react-html-parser"
import BannerCasestudy from "../../components/bannerCasestudy"
import getImage from "../../functions/getImage"
import GET_NEXT_PREVIOUS_ITEMS_QUERY from "../../hooks/nextprevious-news"
import Contact from "../../components/contact"
import { useQuery } from "react-apollo"

export const query = graphql`
  query($slug: String) {
    wpPost(slug: {eq: $slug}) {
      title
      content
      slug
      featuredImage {
        node {
          mediaDetails {
            sizes {
              sourceUrl
              name
            }
          }
        }
      }
    }
    seoPage:wpPost(slug: {eq: $slug}) {
    nodeType
    title
    uri
    seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
    }
  }
  }
`

const GetNextandPreviousItems = ({slug}) => {

  // Fetching the search data from WPGraphQL via Apollo.
  const { data: queriedData } = useQuery(GET_NEXT_PREVIOUS_ITEMS_QUERY, {
    variables: { id:slug },
  })
  
  return (

    <React.Fragment>
      
      <div className="arrow prev">
        {queriedData && queriedData.post.nextPost &&

          <a className="link" rel="noreferrer" href={`/news/${queriedData.post.nextPost.node.slug}`}>
          
          <svg className="arrownext" xmlns="http://www.w3.org/2000/svg" width="40.459" height="69.045" viewBox="0 0 40.459 69.045">
            <path id="Path" d="M40.459,6.172,34.288,0,0,34.522,34.323,69.045l6.137-6.172L12.273,34.522Z"/>
          </svg>

          <div>{queriedData.post.nextPost.node.title}</div>
          
          </a>
        
      }

      </div>

      <div className="arrow next">
      
       {queriedData && queriedData.post.previousPost &&

        <a className="link" rel="noreferrer" href={`/news/${queriedData.post.previousPost.node.slug}`}>
        
        <div>{queriedData.post.previousPost.node.title}</div> 

        <svg className="arrowprevious" xmlns="http://www.w3.org/2000/svg" width="40.459" height="69.045" viewBox="0 0 40.459 69.045">
          <path id="Path" d="M40.459,6.172,34.288,0,0,34.522,34.323,69.045l6.137-6.172L12.273,34.522Z"/>
        </svg>
   
        </a>
       
      }
      </div>

    </React.Fragment>

  )
}

const wpPost = ({
  data: {
    wpPost, seoPage
  },
}) => {

  const {title, content, featuredImage, slug } = wpPost

  return (
    <Layout>
      {seoPage ?
        <Seo post={seoPage} />
      :
        <SeoBasic title={title} />
      }

        <div id="top">
          {featuredImage && featuredImage.node && featuredImage.node.mediaDetails &&
          <BannerCasestudy img={getImage(featuredImage.node.mediaDetails.sizes)} text={title} imgclass="bannerImg news" />
          }
        </div>

        <div className="simpleText news-single" style={{backgroundColor:"#2F2F2F"}}>
      
          {ReactHtmlParser(content)}

        </div>

        <div className="news-next-previous">

         <GetNextandPreviousItems slug={slug} />

        </div>

        <div className="footer-image news"></div>

        <div className="contact news">
          <Contact />
        </div>


        <div className="back-to-top-holder news">
          <a className="back-to-top" href="#top">Back to top </a>
        </div>

    </Layout>
  )
}


export default wpPost