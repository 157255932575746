import * as React from "react"
import ContactForm from "../components/contactForm"
import Envelope from "../components/symbols/envelope"
// import Phone from "../components/symbols/phone"

//style={{backgroundColor:"#2F2F2F"}}

const IndexPage = () => (

	
 <div className="simpleText contact small">
      
      <div className="left">

      <h1>Contact Us</h1>

      <p>Please contact us with your enquiry and we will endeavour to respond within two working days.</p>
      
      <div className="grid-container">
        {/*<div className="grid-item">

        <Phone />

        </div>
        <div className="grid-item"><a href="tel:+44 345 678 903">+44 345 678 903</a></div>*/}
        <div className="grid-item">

        <Envelope />

        </div>
        <div className="grid-item"><a href="mailto:info@conservationcapital.com">info@conservationcapital.com</a></div>
        </div>
        </div>

      <ContactForm emailJS_ID={process.env.GATSBY_EMAILJS_USER_ID} />
    
    </div>



)

export default IndexPage
