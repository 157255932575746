import React, { useState } from "react"
import { useForm } from "react-hook-form";
// import Recaptcha from 'react-google-invisible-recaptcha';
const axios = require('axios');

const ContactForm = () => {

  const { register } = useForm({mode: "onSubmit"});

  // const recaptcha = useRef(null);
  // const [submittedCheckFailedStatus, setSubmittedCheckFailedStatus] = useState('')

  const [values, setValues] = useState({
        name: '',
        email: '',
        enquiry: '',
        message: '',
  });
  
  const handleChange = (e) => {
        // console.log(e.target.value)
        setValues({ ...values, [e.target.id]: e.target.value });
  };

  const isFormValid = () => {
      
      if (!values.name || !values.email || !values.message) {

      return false;}
      else {
      return true;}

  };

  const [response, setResponse] = useState('')

  ////////////////////////////////////////////////////////////////////

  // on submitting form:
  // 1. call recaptcha
  // 2. send token to siteverify - recaptcha.php
  // 3. check score of response. If > 0.5, then progress
  // const handleFileSubmit = async  (e) => {

  //   /* https://www.youtube.com/watch?v=5jbr0j8hYqE&t=318s */
  //   /* https://stevencotterill.com/articles/adding-google-recaptcha-v3-to-a-php-form */
  //   const token = await recaptcha.current.execute();

  //   if(token){

  //     axios.post(process.env.GATSBY_CAPTCHA_URL,
  //       { 
        
  //       secret: process.env.GATSBY_CAPTCHA_SECRETKEY,
  //             response: token,
      
      
  //     }
  //     )
  //     .then(response => {
        
  //       if(response.data>0.5){

  //         // onSubmit()
  //         console.log('_______')
  //       }
  //       else{

  //         setSubmittedCheckFailedStatus("Google Recaptcha failed. Please try again")
  //       }


  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });

  //   }

  // }


  // const handleFileSubmitApproved = (event) => {

  // }

  ///////////////////////////////////////////////////////////////////

  const onSubmit = (e) => {

    e.preventDefault()

    if (!isFormValid()) {

      setResponse('Please fill in all the fields')

    }
    else{
 
      axios.post(process.env.GATSBY_CONTACT_EMAIL, {
        data:{
          email: values.email,
          message: values.message,
          name: values.name,
          question: values.enquiry
        }
      })
      .then(function (response) {
        console.log(response.data)

        if(response.data==='success'){

          setResponse('Email sent. Thankyou!')
        }

        if(response.data==='failed'){

          setResponse('Email not sent. Please try again')
        }


      })
      .catch(function (error) {
        console.log('error' + error);
      });

    }

  };

  const ShowForm =  (

      <form id='contact-form' onSubmit={onSubmit} method="post">
      <label htmlFor="name">
        
        <input
          className="top"
          type="text"
          name="name"
          id="name"
          placeholder="Name"
          {...register('name', { required: true })}
          onChange={handleChange}
        />
      </label>
      <label htmlFor="email">
        <input
          className="top right"
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          {...register('email', { required: true })}
          onChange={handleChange}
        />
      </label>
      <label htmlFor="question">
        <textarea
          className="bottom"
          {...register('Message', { required: true })}
          name="message"
          id="message"
          rows="3"
          placeholder="Message"
          onChange={handleChange}
        />
      </label>
      <div className="button-holder"><button type="submit">Send</button></div>
    </form>
  
  )

return (

    <div className="right">
      
      {response}
      
      {response!=='Email sent. Thankyou!' &&
      ShowForm
      }

       {/* <Recaptcha
            ref={recaptcha}
            sitekey={process.env.GATSBY_CAPTCHA_SITEKEY}
            onResolved={handleFileSubmitApproved}
        />*/}

      </div>

  )

}

export default ContactForm
